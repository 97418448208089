.profileContainer {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    margin-top: 100px;
    background-color: rgb(7 24 39);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profileHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  
  .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  
  .userName {
    font-size: 18px;
    color: #fff;
    margin-left: -20px;

  }
  
  .profileDetails {
    margin-top: 20px;
    background-color: orange;
    color: #fff;
    width: 100px;
    border-radius: 50px;
    border: 2px solid #fff;
    padding: 1px 20px;
margin-inline: auto;
    text-align: center;
    font-size: 18px;
  }

  .logoutButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f05454;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    width: 100px;
    display: block;
    margin-inline: auto;
  }
  
  .logoutButton:hover {
    background-color: #ff6b6b;
  }
  