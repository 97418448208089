body{
  background: rgb(7 24 39);
}
.App {
  display: flex;
  height: 100vh; /* Full height of the viewport */
  background-color: rgb(7 24 39);
}

.left-section {
  width: 40%; /* 40% of the total width */
  height: 100%; /* Full height */
  /* padding: 20px;  */
  box-sizing: border-box; /* Include padding in width */
}

.right-section {
  width: 60%; /* 60% of the total width */
  height: 100%; /* Full height */
  padding: 20px; /* Optional padding */
  box-sizing: border-box; /* Include padding in width */
}

/* Mobile styles */
@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Stack sections vertically */
  }

  .left-section {
    width: 100%; /* Full width for left section */
    height: 40vh; /* 40% of viewport height */
  }

  .right-section {
    display: none; /* Hide right section on mobile */
  }
}
