.right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height of the parent */
    margin: 20px; /* Padding for spacing */
    color: white; /* Text color */
    text-align: center; /* Center the text */
  }
  
  .title {
    font-size: 48px; /* Large font for title */
    font-weight: bold; /* Bold text */
    margin: 0 0 10px 0; /* Margin below title */
  }
  
  .subtitle {
    font-size: 18px; /* Subtitle font size */
    margin: 0 0 20px 0; /* Margin below subtitle */
  }
  
  .description {
    font-size: 16px; /* Description font size */
    margin-bottom: 20px; /* Margin below description */
  }
  
  .image-container {
    max-width: 80%; /* Maximum width for the image */
    width: 100%; /* Responsive width */
  }
  
  .image-container img {
    width: 100%; /* Image takes full width of the container */
    height: auto; /* Maintain aspect ratio */
  }
  