.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 40%;
    box-sizing: border-box;
    background-color: rgb(7 24 39);
    color: white;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .logo{
    width:100px
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .profileIcon {
    font-size: 24px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .header {
      width: 100%; 
    }
}