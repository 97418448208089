.quiz-section {
    text-align: center;
    padding:20px;
    padding-top: 80px;
}

.quiz-sub-line{
    color: #FFF;
}

.quiz-section h3 {
    margin-bottom: 20px;
}

.options {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two buttons per row */
    justify-content: space-around; /* Space around buttons */
    gap: 20px; /* Space between the buttons */
    margin-bottom: 20px;
    width: 100%;
}

.options button {
    background-color: #2e2f47; /* Dark background color */
    color: #fff; /* White text */
    border: 2px solid #fff; /* White border */
    padding: 10px 30px; /* Padding for spacing */
    font-size: 16px; /* Adjust the font size */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    outline: none; /* Remove the default button outline */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.info{
    text-align: left !important;
    color: #FFF;
}

.info h4 {
    margin-bottom: 10px;
}

.info ul {
    list-style: none;
    text-align: left;
}

.info li{
    margin-bottom: 10px;
    list-style: disc;
}

.fun-facts{
    color: #FFF;
    border: 2px solid #fff; /* White border */
    padding: 10px 30px; /* Padding for spacing */
    border-radius: 50px; /* Rounded corners */
    margin-top: 50px;
}

.fun-facts h4 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(59 130 246);
}

.fun-facts p {
    font-size: 0.9em;
    color: #ccc;
    line-height: 1.5;
}

.contact {
    margin-top: 20px;
}

.contact button {
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contact button:hover {
    background-color: #004a99;
}