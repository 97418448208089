.cardContainer {
    max-width: 500px;
    margin: 50px auto;
    padding: 10px;
    background-color: rgb(7, 24, 39);
    border-radius: 10px;
    padding-top: 60px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .card {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 50px;
    background-color: rgb(30,40,57);
    border: rgb(75,84,101);
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    justify-content: space-between;
  }
  
  .cardImageContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cardDetails {
    flex-grow: 1;
    padding-left: 10px;
    display: flex;
    text-align: right;
    flex-direction: column;
    justify-content: center;
  }
  
  .cardHeading {
    font-size: 16px; 
    padding-block: 5px;
    color: #fff;
    margin: 0;
  }
  
  .cardText {
    font-size: 10px;
    color: rgb(57,205,249);
    margin: 0;
  }
  
  .cardFee {
    font-size: 10px;
    font-weight: bold;
    color: rgb(0,186,85);
    display: block;
    margin: 0;
  }
  
  .cardEndImageContainer {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardEndImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  